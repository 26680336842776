import { setCurrentTrack } from "../player/setCurrentTrack";
import { apiService } from "../services";
import { getAudioElement } from "../player/getAudioElement";
import { isSafari } from "../utils/isSafari";
import { onOnlineAfterOfflineAudioEnded } from "./onOnlineAfterOfflineAudioEnded";

let alerted = false;

export function onOffline() {
  console.warn("onOffline()");

  if (window.MeuPlay.isOnline) {
    window.MeuPlay.isOnline = false;
    console.log("window.MeuPlay.isOnline = false");
  }
  if (window.MeuPlay.isOfflinePlaying) {
    console.log("conexão ficou offline novamente, nada a ser feito");
    return;
  }

  document.body.classList.remove("online");
  setCurrentTrack("Offline Mode", undefined, true);
  window.MeuPlay.setNextTrack?.({ title: "", artwork: "" });
  window.MeuPlay.setOfflineModeStatus?.("Você está no<br>Modo Offline");

  const audio = getAudioElement();
  let audioSource = audio.querySelector("source");
  if (audio && !audioSource) {
    console.log("[OFFLINE MODE] Elemento <source/> criado automaticamente.");
    audioSource = document.createElement("source");
    audio.appendChild(audioSource);
  }
  if (!audio || !audioSource) {
    console.log(
      "[OFFLINE MODE] Elementos <audio/> e <source/> não foram encontrados."
    );
    return;
  }
  // const playing = isPlaying();
  // if (!playing) {
  //   console.log("[OFFLINE MODE] Ficou offline mas está pausado.");
  //   onAutoPlayError("[OFFLINE MODE] Ficou offline mas está pausado.");
  //   return;
  // }
  const offlineAudios = apiService.getOfflineAudios();
  if (offlineAudios.length <= 0) {
    console.log("[OFFLINE MODE] Ficou offline mas não tem áudios em cache.");
    return;
  }
  console.log("[OFFLINE MODE] Ficou offline, iniciando áudio em cache...");

  // pausa o áudio principal e os áudios agendados
  document.querySelectorAll("audio").forEach((el) => el.pause());

  // toca o áudio offline
  let offlineIndex = -1;
  window.MeuPlay.playNextOfflineAudio = function playNextOfflineAudio() {
    console.log("playNextOfflineAudio()");
    offlineIndex++;
    if (!offlineAudios[offlineIndex]) {
      offlineIndex = 0;
    }

    const fallbackIndex = offlineIndex;
    while (
      window.localStorage.getItem(offlineAudios[offlineIndex]) === "played"
    ) {
      console.log(
        `[OFFLINE MODE] Música offline ${offlineAudios[offlineIndex]} já foi tocada, pulando...`
      );
      offlineIndex++;
      if (!offlineAudios[offlineIndex]) {
        offlineIndex = fallbackIndex;
        console.log(
          `[OFFLINE MODE] Música offline ${offlineAudios[offlineIndex]} já foi tocada, mas tocando ela mesmo assim.`
        );
        break;
      }
    }

    console.log(
      `[OFFLINE MODE] Tocando índice ${offlineIndex}:`,
      offlineAudios[offlineIndex]
    );

    const split = decodeURI(offlineAudios[offlineIndex]).split("/");
    const filename = split[split.length - 1];
    const currentTrack = filename.split(".").slice(0, -1).join(".");
    setCurrentTrack(currentTrack, undefined, true);

    if (isSafari) {
      // safari
      audio.src = offlineAudios[offlineIndex];
      if (audioSource) {
        audio.removeChild(audioSource);
      }
    } else {
      // other browsers
      audio.src = offlineAudios[offlineIndex];
      if (audioSource) {
        audioSource.src = offlineAudios[offlineIndex];
      }
    }

    // debugAudio(audio);
    audio.crossOrigin = "anonymous";
    audio.load();

    const playResult = audio.play();
    if (playResult instanceof Promise) {
      playResult.then(
        () => {},
        (e) => {
          console.error("Erro ao tocar áudio offline:", e);
          if (!alerted) {
            alerted = true;
            // alert('Erro ao tocar áudio offline');
          }
        }
      );
    }

    const bgBlur = document.querySelector<HTMLDivElement>("#bg_blur");
    if (bgBlur) {
      bgBlur.style.backgroundImage = "url('/play-audio.png')";
    }
  };

  window.MeuPlay.playNextOfflineAudio();

  function onOfflinePlaying() {
    window.MeuPlay.isOfflinePlaying = true;
    window.localStorage.setItem(offlineAudios[offlineIndex], "played");
    console.log("window.MeuPlay.isOfflinePlaying = true");
  }
  window.MeuPlay.onOfflinePlaying = onOfflinePlaying;

  function onOfflineEnded() {
    window.MeuPlay.isOfflinePlaying = false;
    console.log("window.MeuPlay.isOfflinePlaying = false");
    if (window.MeuPlay.isOnline) {
      // áudio offline terminou, mas já está online
      onOnlineAfterOfflineAudioEnded();
    } else {
      // áudio offline terminou, mas permanece offline
      window.MeuPlay.playNextOfflineAudio?.();
    }
  }
  window.MeuPlay.onOfflineEnded = onOfflineEnded;

  audio.removeEventListener("playing", window.MeuPlay.onOfflinePlaying);
  audio.addEventListener("playing", window.MeuPlay.onOfflinePlaying);

  audio.removeEventListener("ended", window.MeuPlay.onOfflineEnded);
  audio.addEventListener("ended", window.MeuPlay.onOfflineEnded);
}
