import swal from "sweetalert";
import { useEffect, useRef, useState } from "react";
import { getAudioElement } from "../../player/getAudioElement";
import { exitOfflineMode } from "../../offline_mode/exitOfflineMode";
import { disableOfflineModeListeners } from "../../offline_mode/disableOfflineModeListeners";
import "./style.scss";
import { hardRefresh } from "../../utils/hardRefresh";

const defaultIndicator: OfflineModeIndicator = {
  loaded: true,
  color: "red",
  text: "Instalando<br>Modo Offline",
};

export function OfflineModeIndicator() {
  const [status, setStatus] = useState(defaultIndicator.text);
  const [testMode, setTestMode] = useState(false);

  useEffect(() => {
    window.MeuPlay.setOfflineModeStatus = setStatus;

    function onUpdateOfflineModeIndicator() {
      const { loaded, color, text } =
        window.MeuPlay.OfflineModeIndicator || defaultIndicator;
      const bolinha: HTMLElement | null = document.querySelector(".bolinha");
      if (loaded && bolinha) {
        bolinha.style.color = color;
        setStatus(text);
      }
    }
    document.addEventListener(
      "updateOfflineModeIndicator",
      onUpdateOfflineModeIndicator
    );

    window.MeuPlay.OfflineModeIndicator =
      window.MeuPlay.OfflineModeIndicator || defaultIndicator;

    window.MeuPlay.OfflineModeIndicator.loaded = true;

    // atualiza a cor da bolinha
    document.dispatchEvent(new CustomEvent("updateOfflineModeIndicator"));

    return () => {
      document.removeEventListener(
        "updateOfflineModeIndicator",
        onUpdateOfflineModeIndicator
      );
    };
  }, []);

  const clicks = useRef(0);
  return (
    <div
      className="offline-indicator"
      onClick={
        testMode
          ? undefined
          : (event) => {
              event.preventDefault();
              event.stopPropagation();
              if (clicks.current === 4) {
                window.MeuPlay.forceOfflineMode = true;
                swal({
                  title: "Modo Teste",
                  text: "Você ativou o modo de teste do modo offline!",
                });
                document.dispatchEvent(
                  new CustomEvent("statusChanged", { detail: "offline" })
                );
                setTimeout(() => {
                  disableOfflineModeListeners();
                });
                setTestMode(true);
              } else {
                clicks.current++;
              }
            }
      }
    >
      <span className="bolinha">⬤</span>
      <span className="texto" dangerouslySetInnerHTML={{ __html: status }} />
      {testMode && (
        <div className="buttons">
          <button
            type="button"
            onClick={() => {
              window.MeuPlay.playNextOfflineAudio?.();
            }}
          >
            Próxima música offline
          </button>
          <button
            type="button"
            onClick={() => {
              const audio = getAudioElement();
              if (audio.duration && audio.duration !== Infinity) {
                audio.currentTime = audio.duration - 5;
              }
            }}
          >
            Avançar para o final da música offline
          </button>
          <button
            type="button"
            onClick={() => {
              clicks.current = 0;
              setTestMode(false);
              exitOfflineMode();
            }}
          >
            Sair do modo de teste
          </button>
          <button type="button" onClick={hardRefresh}>
            Atualizar página
          </button>
          <button
            onClick={() => {
              // @ts-ignore
              methodDoesNotExist();
            }}
          >
            Provocar erro
          </button>
        </div>
      )}
    </div>
  );
}
