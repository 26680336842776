import { getAudioElement } from "../player/getAudioElement";

export function onOnline() {
  window.MeuPlay.isOnline = true;
  console.log("window.MeuPlay.isOnline = true");

  // substitui as músicas armazenadas em cache que já foram tocadas
  const replace = [];
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (!key) {
      continue;
    }
    const value = window.localStorage.getItem(key);
    if (value === "played") {
      const audio = getAudioElement();
      const currentSrc = audio.currentSrc;
      if (key === currentSrc) {
        console.log(
          `[OFFLINE MODE] Ficou online, mantendo a música ${key} do modo offline porque é a música sendo tocada.`
        );
      } else {
        replace.push(key);
      }
    }
  }
  if (replace.length > 0) {
    // console.log( '[OFFLINE MODE] Ficou online, substituindo a(s) seguinte(s) música(s) do modo offline:', replace);
    window.MeuPlay.fetchOfflineMode?.();
  }
}
