import * as Sentry from "@sentry/react";
import swal from "sweetalert";

/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";

import { Audio } from "../../components/Audio";
import { InstallPwa } from "../../components/InstallPwa";
import { LoginInfo } from "../../components/LoginInfo";
import { Modal } from "../../components/Modal";
import { OfflineModeIndicator } from "../../components/OfflineModeIndicator";
import { TrackInfo } from "../../components/TrackInfo";
import { useRadio } from "../../hooks/useRadio";
import { getAudioElement } from "../../player/getAudioElement";
import { getVolume } from "../../player/getVolume";
import { loadRadio } from "../../player/loadRadio";
import { storageService } from "../../services/StorageService";
import { useLoginState } from "../../states/useLoginState";
import { loadSocket } from "../../utils/loadSocket";
import { Loading } from "../Loading";

import Cron from "croner";
import startOfDay from "date-fns/startOfDay";
import { handleBeforeUnload } from "../../features/handleBeforeUnload";
import { onFirstLoad } from "../../features/onFirstLoad";
import { useListener } from "../../hooks/useListener";
import { play } from "../../player/play";
import { TAudio } from "../../services";
import { useVersionState } from "../../states/useVersionState";
import { shuffle } from "../../utils/shuffle";
import "./style.scss";
import { ElevenLabs } from "./tts/ElevenLabs";
import { RadioIndoor } from "./tts/RadioIndoor";

const $: JQueryStatic = window.jQuery;

let monitorando = false;

// -- start canopy --
const images = shuffle(
  Array.from(
    { length: 17 },
    (_, i) => `/canopy/img_${String(i + 1).padStart(2, "0")}.jpg`
  )
).join(" ");
// -- end canopy --

const defaultVolume = getVolume();

function highlightNextAudio() {
  setTimeout(() => {
    const highlights = document.querySelectorAll<HTMLDivElement>(
      ".audio-component.highlight"
    );
    if (highlights[0]) {
      highlights[0].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      highlights.forEach((highlight) => {
        setTimeout(() => {
          highlight.classList.add("pulsate");
          setTimeout(() => {
            highlight.classList.remove("pulsate");
          }, 1500);
        }, 500);
      });
    }
  }, 500);
}

export function Player() {
  const { currentVersion } = useVersionState();
  const [volume, setVolume] = useState(defaultVolume);

  let volumeIcon;
  if (volume === 0) volumeIcon = "fa fa-volume-mute";
  if (volume > 0 && volume < 50) volumeIcon = "fa fa-volume-down";
  if (volume >= 50) volumeIcon = "fa fa-volume-up";

  useEffect(() => {
    storageService.setItem("volume", volume + "", true);
    const audio = getAudioElement();
    if (audio) {
      audio.volume = volume / 100;
    }
    if (window.MeuPlay.player && window.MeuPlay.player.volume) {
      window.MeuPlay.player.volume(volume);
    }
  }, [volume]);

  const [searchParams, setSearchParams] = useSearchParams();
  const { loading, error, radio } = useRadio();

  const [nextTrack, setNextTrack] = useState<{
    title?: string;
    artwork?: string;
  }>({});
  window.MeuPlay.setNextTrack = setNextTrack;

  const nextTrackTitleRef = useRef<string>();
  useEffect(() => {
    if (nextTrack.title !== nextTrackTitleRef.current) {
      nextTrackTitleRef.current = nextTrack.title;
      document.body.classList.toggle("animacao");
    }
  }, [nextTrack.title]);

  const [prevTracks, setPrevTracks] = useState<
    {
      title: string;
      artwork: string;
      time: string;
    }[]
  >([]);
  window.MeuPlay.setPrevTracks = setPrevTracks;

  const [render, setRender] = useState(1);
  useListener("updateAudiosContent", () => setRender((prev) => prev + 1));
  const audiosContent = useMemo(() => {
    console.log("[updateAudiosContent] definindo audiosContent...");

    const audios = radio?.audios || [];
    if (audios.length === 0) {
      return "Essa rádio não possui áudios cadastrados.";
    }

    const today = startOfDay(new Date());
    const sortedAudios: TAudio[] = [];
    const sortable: (TAudio & { order: number; msToNext: number })[] = [];
    for (const audio of audios) {
      if (audio.cron) {
        const job = Cron(audio.cron);
        const order = job.msToNext(today);
        const msToNext = job.msToNext();
        if (order && msToNext) {
          sortable.push({ ...audio, order, msToNext });
        } else {
          sortedAudios.push(audio);
        }
        job.stop();
      } else {
        sortedAudios.push(audio);
      }
    }

    sortable.sort((a, b) => a.order - b.order);
    for (const audio of sortable) {
      sortedAudios.push(audio);
    }

    sortable.sort((a, b) => a.msToNext - b.msToNext);
    const nextAudios = [];
    for (const audio of sortable) {
      if (
        nextAudios.length === 0 ||
        nextAudios[nextAudios.length - 1].cron === audio.cron
      ) {
        nextAudios.push(audio);
      }
    }
    const nextAudiosIds = nextAudios.map((audio) => audio.id);

    return sortedAudios.map((audio) => (
      <Audio
        key={audio.id}
        id={audio.id}
        name={audio.name}
        url={audio.url}
        cron={audio.cron}
        highlight={nextAudiosIds.includes(audio.id)}
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radio?.audios, radio?.type_server, searchParams, render]);

  const popup = Boolean(searchParams.get("popup"));
  const popup_canopy = radio?.folder === "canopybyhilton" && popup;
  useLayoutEffect(() => {
    if (popup_canopy) {
      document.body.id = "popup_canopy";
    }
  }, [popup_canopy]);

  useEffect(() => {
    if (radio?.id) {
      loadSocket(radio);

      setTimeout(() => {
        onFirstLoad(radio);
        loadRadio(radio);
      });

      return handleBeforeUnload(radio?.latest_record);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radio?.id]);

  // caso o cron do áudio não tenha vindo do back-end, preenche-o a partir da query string ?cron=*/5 * * * *
  // exemplo: http://localhost:3000/recifeinterna?cron=*%2F5%20*%20*%20*%20*
  useEffect(() => {
    const audios = radio?.audios ?? [];
    if (audios.length === 0) {
      return;
    }

    const cronFromQueryString = searchParams.get("cron");
    if (cronFromQueryString) {
      console.log("injetando cron no primeiro áudio utilizando query string");
      audios[0].cron = cronFromQueryString;
      searchParams.delete("cron");
      setSearchParams(searchParams, { replace: true });
    }
  }, [radio?.audios, searchParams, setSearchParams]);

  const toggleAnimation = useCallback(() => {
    if ($(".history").hasClass("collapsed")) {
      // Alterna a animação caso "últimas tocadas" esteja fechada
      document.body.classList.toggle("animacao");
    } else {
      // Fecha "últimas tocadas" caso esteja aberta
      $(".history").addClass("collapsed");
    }

    if (!monitorando) {
      const replay = Sentry.getReplay();
      if (replay) {
        replay.flush();
        monitorando = true;
        swal({
          title: "Monitoramento ativado.",
          icon: "success",
        });
      } else {
        swal({
          title: "Monitoramento NÃO ativado.",
          icon: "warning",
        });
      }
    }
  }, []);

  useEffect(() => {
    if (error?.error === "data_not_found") {
      useLoginState.getState().logout(false);
    } else if (error?.error === "login_and_password_required") {
      useLoginState.setState({ loggedIn: false });
    } else if (error?.error === "disabled_radio") {
      useLoginState.setState({ loggedIn: false, error: "Rádio inativa" });
    }
  }, [error?.error]);

  if (loading) {
    return <Loading message="Localizando rádio..." />;
  }

  if (error?.error) {
    return (
      <div className="Player-page">
        <div>
          <img
            src="/logo192.png"
            alt="Fabricca Multimídia"
            title="Fabricca Multimídia"
          />
        </div>
        <div>{error?.error}</div>
        <div>
          <button
            type="button"
            className="btn btn-warning"
            data-dismiss="modal"
            onClick={() => document.location.reload()}
          >
            Tentar novamente
          </button>
        </div>
      </div>
    );
  }

  const overflowPlayer = (
    <div id="overflow_player">
      <div>
        <img src="/loading.gif" alt="" />
        <a
          href="#"
          title="Play"
          onClick={(event) => {
            event.preventDefault();
            play();
            return false;
          }}
        >
          <i className="fa fa-play-circle"></i>
        </a>
      </div>
    </div>
  );

  if (!radio) {
    return <></>;
  }

  return (
    <>
      {!popup && <InstallPwa />}
      <input type="hidden" id="radio_id" value={radio.id} />
      <div className="container_radio_home">
        <div id="bg_blur"></div>
        <div id="barra_topo" className="container">
          <div className="logo_container">
            <img
              src="/images/logo-white-border.png"
              alt="Fabricca Multimídia"
              title="Fabricca Multimídia"
            />
          </div>
          <div className="version">
            <span>v{currentVersion}</span>
          </div>
        </div>
        <div className="container radio">
          <div className="columns">
            <div className="history collapsed">
              <div
                className="btn-close"
                onClick={() => $(".history").addClass("collapsed")}
              >
                <span className="fa fa-times" />
              </div>
              {prevTracks.map((prevTrack) => (
                <TrackInfo
                  key={prevTrack.time}
                  title={prevTrack.title}
                  artwork={prevTrack.artwork}
                  time={prevTrack.time}
                />
              ))}
            </div>
            {/* HLS (m3u8) */}
            <div id="hls-container" onClick={toggleAnimation}>
              {/* <audio id="audio" type="audio/m4a"></audio> */}
              <audio id="audio"></audio>
              <img id="artwork" alt="" src="/images/logo-white-border.png" />
            </div>
            {/* Non-HLS */}
            <div
              id="player"
              className="radioplayer"
              data-src={radio.url}
              data-autoplay="false"
              data-playbutton="false"
              data-volumeslider="false"
              data-elapsedtime="false"
              data-nowplaying="false"
              data-showplayer="false"
              data-volume={volume}
              data-showartwork="true"
              onClick={toggleAnimation}
            >
              <img id="offline" alt="" src="/play-audio.png" />
              {radio.offline_mode ? <OfflineModeIndicator /> : <></>}
              {popup_canopy && (
                <ken-burns-carousel
                  images={images}
                  fade-duration={2500}
                  slide-duration={8000}
                />
              )}
            </div>
          </div>
          <div className="infos_bottom">
            {popup_canopy && overflowPlayer}
            {radio.type_server !== "hls" && (
              <div
                className="prev_tracks"
                onClick={() => $(".history").toggleClass("collapsed")}
              >
                <span className="fa fa-search"></span>
                <span>ÚLTIMAS TOCADAS</span>
              </div>
            )}
            <div className="content">
              <div className="metadata">
                <p id="song"></p>
                <p id="artist"></p>
              </div>
              <div className="controls">
                <div className="botoes">
                  <div
                    className="botao"
                    title="Play/Pause"
                    data-toggle="tooltip"
                    data-placement="top"
                  >
                    <a href="#" id="play_pause">
                      {/* o conteúdo dessa tag é carregado pelo main.js */}
                    </a>
                  </div>
                  {radio.tts >= 1 && (
                    <div
                      className="botao"
                      title="Locutor Virtual<br>Texto Para Áudio (TTS)"
                      data-html="true"
                      data-toggle="tooltip"
                      data-placement="top"
                      onClick={() => {
                        $("#tts_modal").modal("show");
                      }}
                    >
                      <div
                        id="tts"
                        style={{ verticalAlign: "middle" }}
                        className="fa fa-microphone"
                      ></div>
                    </div>
                  )}
                  {!popup_canopy && (
                    <div
                      className="botao"
                      title="Fale conosco"
                      data-toggle="tooltip"
                      data-placement="top"
                      onClick={() => {
                        $("#email").modal("show");
                      }}
                    >
                      <div
                        className="fa fa-envelope"
                        style={{ verticalAlign: "middle" }}
                      ></div>
                    </div>
                  )}
                  {radio.ask_song && (
                    <div
                      className="botao"
                      title="Peça sua música"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <div
                        className="pedir"
                        style={{
                          cursor: "pointer",
                          display: "inline-block",
                          verticalAlign: "top",
                        }}
                        onClick={() => {
                          $("#pedir_musica").fadeIn();
                        }}
                      >
                        <img alt="" src="/images/ask.png" />
                      </div>
                    </div>
                  )}
                  {(radio.audios ?? []).length > 0 && (
                    <div
                      className="botao"
                      title="Áudios"
                      data-toggle="tooltip"
                      data-placement="top"
                      onClick={() => {
                        $("#audios_modal").modal("show");
                        document.dispatchEvent(
                          new Event("updateAudiosContent")
                        );
                        highlightNextAudio();
                      }}
                    >
                      <div
                        style={{ verticalAlign: "middle" }}
                        className="fa fa-voicemail"
                      ></div>
                    </div>
                  )}
                </div>
                <div className="volume">
                  <i className={volumeIcon} />
                  <input
                    type="range"
                    value={volume}
                    min="0"
                    step="1"
                    max="100"
                    title="Volume"
                    placeholder="Volume"
                    onChange={(event) => {
                      const newVolume = event.currentTarget.valueAsNumber;
                      setVolume(newVolume);
                      console.log("🔊 Volume alterado para:", newVolume);
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                  />
                </div>
              </div>
              {popup_canopy && (
                <span className="radio_name">Rádio Canopy By Hilton</span>
              )}
            </div>
            {nextTrack.title && (
              <div id="next_track">
                <span>A SEGUIR</span>
                <TrackInfo
                  title={nextTrack.title ?? ""}
                  artwork={nextTrack.artwork}
                />
              </div>
            )}
          </div>
        </div>
        <div id="redes">
          <LoginInfo requireLogin={Boolean(radio.require_login)} />
          {/* <a
            href="https://twitter.com/fabriccasuporte"
            target="_blank"
            title="Twitter"
            data-toggle="tooltip"
            data-placement="left"
            rel="noreferrer"
          >
            <i className="fab fa-twitter-square"></i>
          </a>
          <br /> */}
          <a
            href="https://www.instagram.com/fabriccamultimidia/"
            target="_blank"
            title="Instagram"
            data-toggle="tooltip"
            data-placement="left"
            rel="noreferrer"
          >
            <i className="fab fa-instagram-square"></i>
          </a>
          <br />
          <a
            href="https://www.facebook.com/fabriccamultimidia/"
            target="_blank"
            title="Facebook"
            data-toggle="tooltip"
            data-placement="left"
            rel="noreferrer"
          >
            <i className="fab fa-facebook-square"></i>
          </a>
          <br />
          <a
            href="https://fabriccamultimidia.com.br/"
            target="_blank"
            title="Site"
            data-toggle="tooltip"
            data-placement="left"
            rel="noreferrer"
          >
            <i className="fa fa-globe" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <Modal id="audios_modal" title="Áudios" content={audiosContent} />
      <div id="notifications" className="modal fade" role="dialog">
        <div className="modal-dialog">
          {/* Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title">Notificações</h4>
            </div>
            <div className="modal-body"></div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
      <audio id="tts_audio">
        <source src="" />
      </audio>
      <div id="tts_modal" className="modal fade" role="dialog">
        <div className="modal-dialog">
          {/* Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title">TTS</h4>
            </div>
            <div className="modal-body">
              {radio.tts === 1 && <RadioIndoor />}
              {radio.tts === 2 && <ElevenLabs />}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="email" className="modal fade" role="dialog">
        <div className="modal-dialog">
          {/* Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title">Contato</h4>
            </div>
            <div className="modal-body">
              <form>
                <input
                  type="text"
                  className="form-control"
                  name="nome"
                  placeholder="Nome"
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  name="departamento"
                  placeholder="Departamento"
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  name="assunto"
                  placeholder="Assunto"
                  required
                />
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="E-mail"
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  name="telefone"
                  placeholder="Telefone"
                  required
                />
                <textarea
                  className="form-control"
                  name="mensagem"
                  placeholder="Mensagem"
                  required
                ></textarea>
                <br />
                <input
                  type="submit"
                  value="Enviar Mensagem"
                  className="btn btn-primary"
                />
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
      {!popup_canopy && overflowPlayer}
      <div id="pedir_musica">
        <a href="#" id="close_pop_ask">
          <i className="fa fa-window-close" aria-hidden="true"></i>
        </a>
        <div id="content_pedir">{radio.ask_song}</div>
      </div>
    </>
  );
}
