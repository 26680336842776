import Hls from "hls.js";
import { onStart } from "./onStart";
import { onStop } from "./onStop";
import { loadMetadata } from "./loadMetadata";

export function loadRadioHls(radio: TRadio) {
  console.log("loading HLS radio with m3u8:", radio.url);
  const hlsPlayer = document.getElementById("audio") as HTMLAudioElement;
  hlsPlayer.onplay = onStart;
  hlsPlayer.onpause = onStop;
  if (Hls.isSupported()) {
    // https://github.com/video-dev/hls.js/blob/master/docs/API.md
    const config = {
      // debug: true,
      startLevel: undefined,
      levelLoadingTimeOut: 10000,
      levelLoadingMaxRetry: 99,
      levelLoadingRetryDelay: 500,
      levelLoadingMaxRetryTimeout: 10000,
      fragLoadingTimeOut: 10000,
      fragLoadingMaxRetry: 99,
      fragLoadingRetryDelay: 500,
      fragLoadingMaxRetryTimeout: 10000,
      startFragPrefetch: false,
    };
    const hls = new Hls(config);
    hls.loadSource(radio.url);
    hls.attachMedia(hlsPlayer);
    hls.on(Hls.Events.ERROR, function (_event, data) {
      console.log("Hls.Events.ERROR", _event, data);
    });
    hls.on(Hls.Events.FRAG_LOADED, function (_event, data) {
      const dadosJson: string | undefined = data.frag.tagList[0][2];
      if (dadosJson) {
        const dados: HlsRadioData = JSON.parse(dadosJson);
        const artwork = dados.imagem ? "https:" + dados.imagem : "";
        loadMetadata(radio, dados.titulo, artwork);
      }
    });
  } else {
    alert("Este navegador não suporta a tecnologia HLS");
  }
}
