import swal from "sweetalert";
import { ttsElevenLabs } from "./ttsElevenLabs";
import { ttsRadioIndoor } from "./ttsRadioIndoor";

const $: JQueryStatic = window.jQuery;

export function ttsLoad(radio: TRadio) {
  // libera o botão "Falar" ao abrir a janela modal
  $("#tts_modal").on("shown.bs.modal", function () {
    $("#tts_modal fieldset").removeProp("disabled");
  });

  // limpa o formulário quando a janela modal for fechada
  $("#tts_modal").on("hide.bs.modal", function () {
    $("#tts_modal fieldset").removeProp("disabled");
    $<HTMLFormElement>("#tts_modal form")[0].reset();
  });

  $("#tts_modal form").submit(function (event) {
    const $ttsForm = $<HTMLFormElement>("#tts_modal form");

    let text = $ttsForm.find(":input[name='text']").val();
    if (typeof text !== "string") {
      console.error("Falha ao determinar o texto a ser falado.");
      return;
    }
    text = text.trim();

    const voice = $ttsForm.find(":input[name='voice']").val();
    if (typeof voice !== "string") {
      console.error("Falha ao determinar a voz a ser utilizada.");
      return;
    }

    const beep = $ttsForm.find(":input[name='beep']").is(":checked");

    // impede o envio caso a mensagem esteja em branco
    if (text === "") {
      swal({
        title: "Aviso",
        text: "Digite o texto a ser falado.",
        icon: "warning",
      });
      event.preventDefault();
      return false;
    }

    // bloqueia o botão de submit por alguns segundos para evitar envios repetidos
    $("#tts_modal fieldset").prop("disabled", "disabled");
    $("#processando").html("Aguarde, processando...");

    const onPlay = () => {
      $("#processando").html("Reproduzindo...");
    };
    const onEnded = () => {
      $("#tts_modal fieldset").removeProp("disabled");
    };
    if (radio.tts === 1) {
      ttsRadioIndoor(text, voice, onPlay, onEnded, beep);
    } else if (radio.tts === 2) {
      ttsElevenLabs(text, voice, onPlay, onEnded);
    } else {
      console.warn("Provedor de TTS não identificado.");
    }

    event.preventDefault();
    return false;
  });
}
