import { checkConnection } from "../offline_mode/checkConnection";
import { exitOfflineMode } from "../offline_mode/exitOfflineMode";
import { onOffline } from "../offline_mode/onOffline";
import { TimerRef } from "../types";
import { isSafari } from "../utils/isSafari";
import { getAudioElement } from "./getAudioElement";

function retry(called_by: string) {
  checkConnection(
    () => {
      console.log(`[retry] ${called_by}; online`);
      exitOfflineMode();
    },
    () => {
      console.log(`[retry] ${called_by}; offline`);
      onOffline();
    }
  );
}

function onStalled() {
  retry("stalled");
}

const timerRef: TimerRef = {
  timer: undefined,
};

function onWaiting() {
  if (typeof timerRef.timer !== "undefined") {
    clearTimeout(timerRef.timer);
  }
  timerRef.timer = setTimeout(() => {
    const audio = getAudioElement();
    if (audio.readyState === HTMLMediaElement.HAVE_NOTHING) {
      console.warn("[autoRetry] HTMLMediaElement.HAVE_NOTHING");
      retry("waiting");
    } else {
      console.warn("[autoRetry] audio.readyState=", audio.readyState);
    }
  }, 15000);
}

export function autoRetry(audio: HTMLAudioElement) {
  if (!audio.dataset.autoRetry) {
    audio.dataset.autoRetry = "1";

    if (isSafari) {
      audio.removeEventListener("stalled", onStalled);
      audio.addEventListener("stalled", onStalled);
    }

    audio.removeEventListener("waiting", onWaiting);
    audio.addEventListener("waiting", onWaiting);
  }
}
