import { playAnotherAudio } from "../../player/playAnotherAudio";
import { streamToBlob } from "../../utils/streamToBlob";

export function ttsElevenLabs(
  text: string,
  voice: string,
  onPlay: () => void,
  onEnded: () => void
) {
  const token = "b3vutd5cvgg5mc1tdnnse8c2kytwapsl";
  fetch(`https://tts.fabriccamultimidia.com.br/?token=${token}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ text, voice }),
  })
    .then((response) => {
      if (!response.body) {
        console.error("Falha ao ler a resposta de ttsElevenLabs:", response);
        return;
      }
      streamToBlob(response.body).then((blob) => {
        playAnotherAudio(document.querySelector("#tts_audio")!, {
          src: URL.createObjectURL(blob),
          playbackRate: window.MeuPlay.playbackRate,
          onPlay,
          onEnded,
        });
      });
    })
    .catch((error) => console.log("tts error:", error));
}
