import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { env } from "../../env";
import { storageService } from "../../services/StorageService";

import "./style.scss";
import { useVersionState } from "../../states/useVersionState";
import { useRefreshState } from "../../states/useRefreshState";
import { hardRefresh } from "../../utils/hardRefresh";

export function Container() {
  const { blankScreen } = useRefreshState();

  const [isHomolog, setIsHomolog] = useState(false);

  // utilizado pelo script jquery.radiocoplayer.min.js
  useEffect(() => {
    if (typeof window.jQuery?.getJSON !== "undefined") {
      window.jQuery.getJSON("/manifest.json").then(
        (data: { id: string }) => {
          setIsHomolog(String(data?.id).startsWith("hom_"));
        },
        () => {}
      );
    }

    storageService.setItem("url_api", env.api.host, true);
  }, [setIsHomolog]);

  let content;
  const { currentVersion, latestVersion } = useVersionState();
  if (latestVersion && currentVersion !== latestVersion) {
    content = (
      <div className="center">
        <p>Esse dispositivo possui o player v{currentVersion}</p>
        <p>A versão v{latestVersion} já está disponível.</p>
        <button className="button" type="button" onClick={hardRefresh}>
          Atualizar
        </button>
      </div>
    );
  } else {
    content = <Outlet />;
  }

  if (blankScreen) {
    return <></>;
  }

  return (
    <div className="container-component">
      {isHomolog && <div id="hom">[HOMOLOG]</div>}
      {content}
    </div>
  );
}
