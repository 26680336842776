import { create } from "zustand";
import { apiService, TError } from "../services";
import { storageService } from "../services/StorageService";
import { TGrupo, TRadio } from "../services/types";

export type TLoginState = {
  loading: boolean;
  error: string;
  idRadio: string;
  grupo: TGrupo | null;
  login: string;
  senha: string;
  loggedIn: boolean;
  fetchRadio: () => void;
  setIdRadio: (idRadio: string) => void;
  setLogin: (login: string) => void;
  setSenha: (senha: string) => void;
  logout: (redirect: boolean) => void;
};

export const useLoginState = create<TLoginState>((set, get) => ({
  initialized: false,

  loading: false,

  error: "",

  /** folder da rádio */
  idRadio: storageService.getItem("idRadio") || "",

  grupo: null,

  /** cnpj */
  login: storageService.getItem("login") || "",

  senha: storageService.getItem("senha") || "",

  loggedIn: false,

  setIdRadio(idRadio: string) {
    storageService.setItem("idRadio", idRadio);
    set({ idRadio });
  },

  setLogin(login: string) {
    storageService.setItem("login", login);
    set({ login });
  },

  setSenha(senha: string) {
    storageService.setItem("senha", senha);
    set({ senha });
  },

  fetchRadio() {
    setTimeout(() => {
      const { grupo, idRadio, login, senha } = get();
      const grupoFolder = grupo?.folder ?? "";
      if (!grupoFolder && !idRadio) {
        set({ error: "O nome da rádio é obrigatório" });
        return;
      }
      set({ error: "", loading: true });
      apiService.setUser(login);
      apiService.setPassword(senha);
      // o grupo é usado no lugar do idRadio para checar se o CNPJ pertence ao respectivo grupo
      apiService.getRadio(idRadio || grupoFolder).then((radioOrError) => {
        if (apiService.isError(radioOrError)) {
          const e = radioOrError as TError;
          if (e.httpCode === 404) {
            set({ error: "Rádio não encontrada" });
          } else if (e.httpCode === 401) {
            // login_and_password_required
            console.log("[useLoginState] 401");
            set({ error: "Informe login e senha" });
          } else {
            // prettier-ignore
            const message = {
              missing_authorization_header: "",
              data_not_found: `Rádio ${idRadio} não encontrada`,
              incorrect_login_or_password: "Login e/ou senha incorretos",
              disabled_radio: `Rádio ${idRadio} inativa`
            }[e.error] ?? e.error;
            set({ error: message });
          }
          set({ loading: false, loggedIn: false });
        } else {
          const radio = radioOrError as TRadio;
          console.log("[fetchRadio] radio=", radio);
          set({
            error: "",
            loading: false,
            loggedIn: true,
            idRadio: radio.folder,
          });
        }
      });
    });
  },

  logout(redirect: boolean) {
    const { grupo } = get();
    const grupoFolder = grupo?.folder + "";
    set({
      loading: false,
      error: "",
      idRadio: "",
      grupo: null,
      login: "",
      senha: "",
      loggedIn: false,
    });
    storageService.clear();
    if (redirect) {
      // o bloco abaixo resolve o problema do áudio continuar tocando após o logout
      setTimeout(() => {
        const href = `/${grupoFolder}`;
        console.log("[useLoginState] redirect to:", href);
        window.location.href = href;
      });
    }
  },
}));
