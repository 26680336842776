import { getAudioElement } from "./getAudioElement";
import { onStop } from "./onStop";

export function stop() {
  const audio = getAudioElement();
  if (audio) {
    audio.pause();
  }
  onStop();
}
