import { getAudioElement } from "../player/getAudioElement";
import { refreshTrackInfo } from "../player/refreshTrackInfo";
import { useRefreshState } from "../states/useRefreshState";

export function exitOfflineMode() {
  if (!window.MeuPlay.setOfflineModeStatus || !window.MeuPlay.radio) {
    return;
  }

  console.log("exitOfflineMode()");
  window.MeuPlay.isOnline = true;
  window.MeuPlay.isOfflinePlaying = false;

  document.body.classList.add("online");

  // setCurrentTrack + setPrevTracks
  window.MeuPlay.radioStatus = "online";
  refreshTrackInfo(window.MeuPlay.radio);

  window.MeuPlay.setOfflineModeStatus("Modo Offline<br>Disponível");

  const audio = getAudioElement();
  audio.removeEventListener("playing", window.MeuPlay.onOfflinePlaying);
  audio.removeEventListener("ended", window.MeuPlay.onOfflineEnded);

  audio.pause();
  audio.removeAttribute("src");
  const audioSource = audio.querySelector("source");
  if (audioSource) {
    audioSource.src = window.MeuPlay.radio.url;
  }

  useRefreshState.getState().refresh();
}
