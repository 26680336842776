import { debugAudio } from "../utils/debugAudio";
import { autoRetry } from "./autoRetry";
import { onStart } from "./onStart";

const $: JQueryStatic = window.jQuery;

export function beforePlay(audio: HTMLAudioElement) {
  console.warn("beforePlay()");
  $("#overflow_player").addClass("loading").show();

  debugAudio(audio);

  autoRetry(audio);

  audio.removeEventListener("playing", onStart);
  audio.addEventListener("playing", onStart);
}
