import { env } from "../env";

export function handleBeforeUnload(latest_record: string | undefined) {
  const onUnload = async (e: BeforeUnloadEvent) => {
    if (!latest_record) {
      return;
    }

    e.preventDefault();
    const logoutUrl = env.api.host + "/api/logout-radio/" + latest_record;

    try {
      await fetch(logoutUrl).then((response) => {
        if (!response.ok) {
          throw new Error("Erro de comunicação com o backend");
        }
      });
    } catch (err) {
      console.error(err);
    }
    e.returnValue = "Tem certeza de que deseja sair?";
    return e.returnValue;
  };

  window.addEventListener("beforeunload", onUnload);

  return () => {
    window.removeEventListener("beforeunload", onUnload);
  };
}
