import { playOffline } from "../offline_mode/playOffline";
import { beforePlay } from "./beforePlay";
import { getAudioElement } from "./getAudioElement";
import { isHls } from "./isHls";
import { onAutoPlayError } from "./onAutoPlayError";

export function play() {
  try {
    let audio: HTMLAudioElement | null = null;
    if (window.MeuPlay.radio && isHls(window.MeuPlay.radio)) {
      console.log("play -> hls");
      // Radio Indoor (somente online)
      audio = document.getElementById("audio") as HTMLAudioElement | null;
    } else if (
      window.MeuPlay.radioStatus === "offline" ||
      !window.MeuPlay.isOnline
    ) {
      console.log("play -> offline");
      // Radio.co offline
      playOffline();
      audio = getAudioElement();
    } else {
      console.log("play -> online radio.co");
      // Radio.co online
      audio = getAudioElement();
      audio.crossOrigin = "anonymous";
      audio.load();
    }
    if (audio) {
      console.log("chamando beforePlay()");
      beforePlay(audio);
      console.log("chamando play()");
      const playResult = audio.play();
      if (playResult instanceof Promise) {
        playResult.then(
          () => {
            console.log("play() funcionou");
          },
          (e) => {
            console.log("play() NÃO funcionou");
            onAutoPlayError(e);
          }
        );
      }
    } else {
      console.log("play -> elemento <audio> não encontrado");
    }
  } catch (e) {
    onAutoPlayError(e);
  }
}
