import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Container } from "./components/Container";
import { loginIndex } from "./pages/Login";
import { LoginOrPlayer } from "./pages/LoginOrPlayer";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from './reportWebVitals';

import "./global.scss";
import "./popup_canopy.scss";

/*
 * Importing directly from the element defines the element class
 * and registers it with the global element registry with the tag
 * name 'ken-burns-carousel'.
 */
import "ken-burns-carousel";

import { afterRegister } from "./offline_mode/afterRegister";
import { connectionMonitor } from "./offline_mode/connectionMonitor";
import { apiService } from "./services";
import { useVersionState } from "./states/useVersionState";

Sentry.init({
  dsn: "https://dbcee3be5e0b942e7b2f678bdc66368b@o4507816815165440.ingest.us.sentry.io/4507816818442240",
  ignoreErrors: [
    /request was interrupted/,
    /fetching process for the media resource was aborted/,
    /call stack size exceeded/,
  ],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/meuplay\.fabriccamultimidia\.com\.br/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function blockRightClickAndInspect() {
  document.addEventListener("contextmenu", function (event) {
    event.preventDefault();
  });
  document.addEventListener("keydown", function (event) {
    if (
      event.keyCode === 123 ||
      (event.ctrlKey && event.shiftKey && event.keyCode === 73)
    ) {
      event.preventDefault();
    }
  });
}

if (process.env.NODE_ENV !== "development") {
  blockRightClickAndInspect();
}

window.MeuPlay.afterRegister = afterRegister;

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <>
    <Toaster />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Container />}>
          {Object.keys(loginIndex).map((grupo) => (
            <Route key={grupo} path={grupo} element={loginIndex[grupo]} />
          ))}
          <Route path=":idRadio" element={<LoginOrPlayer />} />
          <Route path=":idRadio/:cnpj" element={<LoginOrPlayer />} />
          <Route path=":idRadio/:cnpj/:senha" element={<LoginOrPlayer />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </>
);

connectionMonitor();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({ afterRegister });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

apiService.getFrontendVersion().then((latestVersion) => {
  const { currentVersion } = useVersionState.getState();
  console.warn(
    `[index.tsx] current version: ${currentVersion} | latest version: ${latestVersion}`
  );
  if (typeof latestVersion === "string") {
    useVersionState.setState({ latestVersion });
  } else {
    console.log(
      "Falha ao determinar a versão mais recente do front-end:",
      latestVersion
    );
  }
});
