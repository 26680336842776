import { exitOfflineMode } from "./exitOfflineMode";
import { isPlaying } from "../player/isPlaying";

export function onOnlineAfterOfflineAudioEnded() {
  document.body.classList.add("online");
  const playing = isPlaying();
  if (!playing) {
    console.log("[OFFLINE MODE] Ficou online mas está pausado.");
    return;
  }
  console.log("[OFFLINE MODE] Ficou online, saindo do modo offline...");
  setTimeout(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (window.MeuPlay.isMobile && !window.MeuPlay.isPwa) {
      urlParams.delete("autoplay");
    } else {
      urlParams.set("autoplay", "1");
    }
    const newUrl =
      document.location.protocol +
      "//" +
      document.location.host +
      document.location.pathname +
      "?" +
      urlParams.toString();
    window.history.replaceState({}, "_", newUrl);
    exitOfflineMode();
  }, 0);
}
