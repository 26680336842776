import swal from "sweetalert";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export function useRedirectAfterLogin(
  idRadio: string,
  loggedIn: boolean,
  login: string
): void {
  const [searchParams, setSearchParams] = useSearchParams();
  const autoplay = searchParams.get("autoplay") === "1" ? "1" : "0";

  const navigate = useNavigate();

  useEffect(() => {
    if (idRadio && loggedIn) {
      const loginString = String(login || "");
      if (loginString) {
        console.log("[useRedirectAfterLogin] navigate (1)");
        navigate(
          `/${idRadio}/${loginString.replace(/\D+/g, "")}?autoplay=${autoplay}`,
          {
            replace: true,
            state: { idRadio, login },
          }
        );
      } else {
        console.log("[useRedirectAfterLogin] navigate (2)");
        navigate(`/${idRadio}`, { replace: true, state: { idRadio } });
      }
    } else {
      // console.log("[useRedirectAfterLogin] don't navigate");
    }
  }, [idRadio, login, loggedIn, autoplay, navigate]);

  useEffect(() => {
    const not_found = searchParams.get("not_found");
    if (not_found) {
      swal({
        title: `O endereço /${not_found} não está disponível.`,
        text: "Verifique se foi digitado corretamente ou entre em contato conosco.",
        icon: "warning",
      });
      searchParams.delete("not_found");
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);
}
