import { create } from "zustand";

const packageJson = require("../../package.json");

export type TVersionState = {
  currentVersion: string;
  latestVersion?: string;
};

export const useVersionState = create<TVersionState>()(() => ({
  currentVersion: packageJson.version,
  latestVersion: undefined,
}));
