import Hls from "hls.js";
import { isIos } from "../utils/isIos";
import { isHls } from "./isHls";
import { loadRadioCo } from "./loadRadioCo";
import { loadRadioHls } from "./loadRadioHls";
import { play } from "./play";

export function loadRadio(radio: TRadio) {
  if (!Hls) {
    console.error("Falha ao carregar a biblioteca Hls");
    return;
  }

  const $: JQueryStatic = window.jQuery;
  if (!$) {
    console.error("Falha ao carregar a biblioteca jQuery");
    return;
  }

  document.body.classList.add("stopped");
  window.MeuPlay.radioStatus = "online";
  window.MeuPlay.isOnline = true;
  window.MeuPlay.isOfflinePlaying = false;

  if (isHls(radio)) {
    loadRadioHls(radio);
  } else {
    loadRadioCo(radio);
  }

  // http://meuplay/teste?autoplay=1
  const autoplay = Boolean(window.location.search.match(/autoplay=1/));
  // alert(
  //   `autoplay=${autoplay} | window.MeuPlay.isMobile=${window.MeuPlay.isMobile} | window.MeuPlay.isPwa=${window.MeuPlay.isPwa} | isIos=${isIos}`
  // );
  if (
    autoplay &&
    !isIos &&
    (!window.MeuPlay.isMobile || window.MeuPlay.isPwa)
  ) {
    play();
  }
}
