import swal from "sweetalert";
import { env } from "../env";
import { getFormData } from "../utils/getFormData";

const $: JQueryStatic = window.jQuery;

export function contactFormLoad(radio: TRadio) {
  $("#email form").submit(function () {
    $("#email form input[type='submit']")
      .val("Enviando, por favor aguarde...")
      .prop("disabled", true);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-Requested-With", "XMLHttpRequest");

    const formData = getFormData($("#email form"));

    fetch(`${env.api.host}/api/support`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        radio_id: radio.id,
        name: formData.nome,
        department: formData.departamento,
        subject: formData.assunto,
        email: formData.email,
        phone_number: formData.telefone,
        message: formData.mensagem,
      }),
      redirect: "follow",
    })
      .then(() => {
        swal({
          title: "Sucesso!",
          text: "Mensagem enviada.",
          icon: "success",
        });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        $<HTMLFormElement>("#email form")[0].reset();
        $("#email form input[type='submit']")
          .val("Enviar Mensagem")
          .prop("disabled", false);
      });

    return false;
  });
}
