import ClipboardJS from "clipboard";
import toast from "react-hot-toast";
import { Loading } from "./Loading";
import { SpeedControl } from "./SpeedControl";
import "./style.scss";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function BreakTime() {
  return (
    <code
      onClick={(event) => {
        new ClipboardJS(event.currentTarget, {
          text: function () {
            return '<break time="1.0s" />';
          },
        });
        toast.success("Código copiado, agora é só colar!", {
          id: "toast",
        });
      }}
    >
      &lt;break time="1.0s" /&gt;
    </code>
  );
}

export function ElevenLabs() {
  // pesquisar por '$("#tts_modal form").submit' no projeto
  return (
    <form className="tts-form">
      <fieldset>
        <p>
          Utilize <code>&nbsp;-&nbsp;</code> (espaço traço espaço) para produzir
          uma pausa curta,
          <br />
          <code>&nbsp;--&nbsp;</code> para pausa média, ou{" "}
          <code>&nbsp;---&nbsp;</code> para pausa longa.
        </p>
        <textarea
          name="text"
          className="form-control"
          maxLength={200}
          placeholder="Digite o texto aqui"
        />
        <select className="form-control" name="voice" title="Voz">
          <option value="Vanessa">Rachel</option>
          <option value="Serena">Serena</option>
          <option value="Matilda">Matilda</option>
        </select>
        <SpeedControl />
        <Loading />
        <input type="submit" value="Falar" className="btn btn-primary" />
      </fieldset>
    </form>
  );
}
