import debounce from "just-debounce";
import {
  fadeOutMainAudio as _fadeOutMainAudio,
  pauseOtherAudios,
  playMainAudio,
} from "./mainAudio";
import { getVolume } from "./getVolume";

const $: JQueryStatic = window.jQuery;

function _playAnotherAudio(
  anotherAudio: HTMLAudioElement,
  {
    src = "",
    onPlay = () => {},
    onPaused = () => {},
    onEnded = () => {},
    onEndedOrPause = () => {},
    playbackRate = 1.0,
    playMainAudioOnEnd = true,
  }
) {
  let paused = false;
  function fadeOutMainAudio(callPlay: boolean) {
    paused = false;
    console.log("[playAnotherAudio] fadeOutMainAudio");
    _fadeOutMainAudio().then(() => {
      if (callPlay) {
        if (src) {
          $(anotherAudio).attr("src", src);
        }
        pauseOtherAudios(anotherAudio);
        anotherAudio.play();
      }
    });
  }

  $(anotherAudio)
    .off("ended.anotherAudio")
    .on("ended.anotherAudio", () => {
      onEndedOrPause();
      onEnded();
      if (playMainAudioOnEnd) {
        playMainAudio();
      }
    });

  $(anotherAudio)
    .off("pause.anotherAudio")
    .on("pause.anotherAudio", () => {
      paused = true;
      onEndedOrPause();
      onPaused();
      if (playMainAudioOnEnd) {
        playMainAudio(1);
      }
    });

  $(anotherAudio)
    .off("play.anotherAudio")
    .on("play.anotherAudio", () => {
      onPlay();
      if (paused) {
        fadeOutMainAudio(false);
      }
    });

  $(anotherAudio)
    .off("playing.anotherAudio")
    .on("playing.anotherAudio", () => {
      const volume = getVolume() / 100;
      if (anotherAudio) {
        anotherAudio.volume = volume;
      }

      console.log("setting playbackRate=", playbackRate);
      anotherAudio.playbackRate = playbackRate;
    });

  fadeOutMainAudio(true);
}

export const playAnotherAudio = debounce(_playAnotherAudio, 500, true);
