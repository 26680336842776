import Cron from "croner";
import cronstrue from "cronstrue/i18n";
import { format } from "date-fns";
import debounce from "just-debounce";
import { useEffect, useMemo, useRef, useState } from "react";
import { getAudioElement } from "../../player/getAudioElement";
import { playAnotherAudio } from "../../player/playAnotherAudio";
import { AudioProps } from "./types";

import { getVolume } from "../../player/getVolume";
import { fadeOutMainAudio, playMainAudio } from "../../player/mainAudio";
import "./style.scss";
import classNames from "classnames";

const $: JQueryStatic = window.jQuery;

// initial state
let index = 0;
let playing = false;
let cronQueue: HTMLAudioElement[] = [];

function _playCronQueue() {
  if (playing) {
    return;
  }

  if (typeof cronQueue[index] !== "undefined") {
    playing = true;
    console.log(`iniciando áudio agendado ${index}; fading out...`);
    playAnotherAudio(cronQueue[index], {
      playMainAudioOnEnd: index === cronQueue.length - 1,
    });
  } else {
    console.log("a fila de áudio agendados chegou ao fim.");

    // reset (initial state)
    index = 0;
    playing = false;
    cronQueue = [];
    playMainAudio();
  }
}

const playCronQueue = debounce(_playCronQueue, 100, false, true);

export function Audio({ id, name, url, cron, highlight }: AudioProps) {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [proximaExecucao, setProximaExecucao] = useState<Date | null>();
  const cronString = useMemo<string>(
    () =>
      cron
        ? cronstrue.toString(cron, {
            use24HourTimeFormat: true,
            locale: "pt_BR",
          })
        : "",
    [cron]
  );

  const key = `${id}_${name}`;
  useEffect(() => {
    if (!cron || !audioRef.current) {
      return;
    }

    $(audioRef.current).addClass("disabled");

    // caso o áudio tenha sido agendado, toca-o no momento desejado
    console.log(`agendando áudio ${key}`);
    const job = Cron(cron, { name: key }, () => {
      setProximaExecucao(job.nextRun());

      const mainAudio = getAudioElement();
      if (!mainAudio) {
        return;
      }

      if (mainAudio.paused) {
        console.log("áudio agendado ignorado porque o player está pausado");
        return;
      }

      if (!$("#audios_modal").data("bs.modal")?.isShown) {
        $("#audios_modal").addClass("visible-while-playing").modal("show");
      }

      console.log("adicionando áudio agendado à fila de reprodução...");
      cronQueue.push(audioRef.current!);
      playCronQueue();
    });

    setProximaExecucao(job.nextRun());

    window.MeuPlay.spots = window.MeuPlay.spots ?? [];
    const i = window.MeuPlay.spots.length;
    window.MeuPlay.spots[i] = job;
    console.log(
      `áudio ${key} agendado para ${job.nextRun()}`,
      `digite \`spots[${i}].trigger();\` para tocá-lo imediatamente (modo teste)`
    );

    return () => {
      job.stop();
      console.log(`agendamento do áudio ${key} cancelado`);
    };
  }, [key, cron, setProximaExecucao]);

  return (
    <div className={classNames("audio-component", { highlight })}>
      <h2>{name}</h2>
      {cron && proximaExecucao ? (
        <>
          <p>{`Agendamento: ${cronString}`}</p>
          <p>{`Próxima execução: ${format(proximaExecucao, "HH:mm")}`}</p>
        </>
      ) : (
        <></>
      )}
      <audio
        controls
        controlsList="nodownload"
        ref={(ref) => {
          audioRef.current = ref;
          if (ref) {
            const onPlay = () => {
              // FADE OUT
              console.log("[Audio] fade out main audio");
              fadeOutMainAudio();

              $(ref)
                .closest(".modal-body")
                .find(".audio-component")
                .removeClass("enabled")
                .addClass("disabled");

              $(ref)
                .closest(".audio-component")
                .removeClass("disabled")
                .addClass("enabled");
            };
            const onPlaying = () => {
              const volume = getVolume() / 100;
              if (ref) {
                ref.volume = volume;
              }
            };
            const onEndedOrPause = debounce(
              () => {
                document.dispatchEvent(new Event("updateAudiosContent"));

                $(".audio-component")
                  .removeClass("enabled")
                  .removeClass("disabled");

                if (!cron || index === cronQueue.length - 1) {
                  // FADE IN
                  console.log("[Audio] fade in main audio");
                  playMainAudio();
                }
              },
              100,
              true
            );
            const onEnded = () => {
              if (
                cron &&
                $("#audios_modal").hasClass("visible-while-playing")
              ) {
                $("#audios_modal").modal("hide");
              }
              onEndedOrPause();

              index++;
              playing = false;
              playCronQueue();
            };
            ref.onplay = () => {
              console.log(">> onplay");
              onPlay();
            };
            ref.onplaying = () => {
              console.log(">> onplaying");
              onPlaying();
            };
            ref.onended = () => {
              console.log(">> onended");
              onEnded();
            };
            ref.onpause = () => {
              console.log(">> pause");
              onEndedOrPause();
            };
          }
        }}
      >
        <source src={url} />
      </audio>
    </div>
  );
}
