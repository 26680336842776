const $: JQueryStatic = window.jQuery;

export function askSongLoad() {
  const contentPedir = $("#content_pedir");
  const scriptUrl = contentPedir.html();
  const script = document.createElement("script");
  contentPedir.append(script);
  script.type = "text/javascript";
  script.addEventListener("load", function onScriptLoad() {
    console.log("pedir música carregado com sucesso :)");
    $('iframe[src^="//embed.radio.co/request/"]').appendTo(contentPedir);

    const pedir = document.querySelector("#content_pedir");
    if (pedir && String(pedir.childNodes[0].nodeValue).includes("radio.co")) {
      pedir.childNodes[0].nodeValue = "";
    }
  });
  script.src = scriptUrl;

  $("#close_pop_ask").click(function () {
    $("#pedir_musica").fadeOut();
  });
}
