import { apiService } from "../services";
import { enableOfflineModeListeners } from "./enableOfflineModeListeners";
import { updateOfflineModeIndicator } from "./updateOfflineModeIndicator";

export function afterRegister() {
  const idRadio = window.location.pathname.split("/")[1] ?? "";
  const broadcast = new BroadcastChannel(idRadio);
  broadcast.onmessage = (event) => {
    if (event.data) {
      // eslint-disable-next-line default-case
      switch (event.data.type) {
        case "CACHED":
          apiService.addOfflineAudio(event.data.payload);
          break;
        case "ALL_CACHED":
          // deleta todas as músicas já tocadas
          for (let i = 0; i < window.localStorage.length; i++) {
            const key = window.localStorage.key(i);
            if (key) {
              const value = window.localStorage.getItem(key);
              if (value === "played") {
                broadcast.postMessage({
                  type: "REMOVE_FROM_CACHE",
                  payload: key,
                });
              }
            }
          }
          break;
      }
    }
  };

  broadcast.postMessage({ type: "REQUEST_CACHED" });
  updateOfflineModeIndicator("yellow", "Preparando<br>Modo Offline");

  enableOfflineModeListeners();
}
