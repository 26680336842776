import { isHls } from "./isHls";

function findElementWithMostChildNodes(elements: HTMLAudioElement[]) {
  if (elements.length === 0) return null;
  return elements.reduce((maxElem, currentElem) => {
    return maxElem.childNodes.length > currentElem.childNodes.length
      ? maxElem
      : currentElem;
  });
}

export function getAudioElement(): HTMLAudioElement {
  let audio: HTMLAudioElement | null = null;

  // HLS (Radio Indoor / Kako)
  if (window.MeuPlay.radio && isHls(window.MeuPlay.radio)) {
    return document.getElementById("audio") as HTMLAudioElement;
  }

  let elements: HTMLAudioElement[] = [];
  document.querySelectorAll("audio").forEach((element) => {
    if (element.getAttribute("id") === "radioco-radioplayer") {
      elements.push(element);
    }
  });
  audio = findElementWithMostChildNodes(elements);
  elements.forEach((element) => {
    if (element !== audio) {
      console.warn("audio#radioco-radioplayer duplicado deletado:", element);
      element.remove();
    }
  });

  if (!audio) {
    audio = document.createElement("audio");
    audio.id = "radioco-radioplayer";
    audio.preload = "none";
    audio.crossOrigin = "anonymous";
    document.body.appendChild(audio);
  }
  return audio;
}
