import debounce from "just-debounce";
import { setCurrentTrack } from "./setCurrentTrack";

function _refreshTrackInfo(radio: TRadio) {
  console.log("refreshTrackInfo (1)");
  if (window.MeuPlay.radioStatus !== "online") {
    return;
  }
  console.log("refreshTrackInfo (2)");
  if (radio?.type_server !== "hls" && radio?.streaming_id) {
    console.log("refreshTrackInfo (3)");
    // A Seguir
    const nextUrl = `https://meuplay.fabriccamultimidia.com.br/radioco/stations/${radio.streaming_id}/next`;
    fetch(nextUrl)
      .then((response) => response.json())
      .then((result) => {
        console.log("refreshTrackInfo (4)");
        const title = result?.next_track?.title;
        const artwork = result?.next_track?.artwork_url;
        window.MeuPlay.setNextTrack?.({
          title,
          artwork,
        });
      })
      .catch((error) => console.log("next error:", error));

    // Últimas tocadas
    const historyUrl = `https://meuplay.fabriccamultimidia.com.br/radioco/stations/${radio.streaming_id}/history`;
    fetch(historyUrl)
      .then((response) => response.json())
      .then((result) => {
        console.log("refreshTrackInfo (5)");
        const historyTracks: (RadiocoHistoryTrack | null)[] =
          result?.tracks || [];
        const prevTracks = historyTracks.map((item) => ({
          title: item?.title || "",
          artwork: item?.artwork_url || "",
          time: item?.start_time || "",
        }));

        // Faixa atual
        const currentTrack = prevTracks.shift();
        if (!window.MeuPlay.isOfflinePlaying && currentTrack) {
          setCurrentTrack(currentTrack.title, currentTrack.artwork, false);
        }

        window.MeuPlay.setPrevTracks?.(prevTracks);
      })
      .catch((error) => console.log("history error:", error));
  }
}

export const refreshTrackInfo = debounce(_refreshTrackInfo, 100, true);
