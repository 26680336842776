import { loadMetadata } from "./loadMetadata";

export function refreshCurrentTrack() {
  if (!window.MeuPlay.radio) {
    return;
  }
  const statusUrl = `https://meuplay.fabriccamultimidia.com.br/radioco/stations/${window.MeuPlay.radio.streaming_id}/status`;
  $.getJSON(statusUrl, {}, (response) => {
    /*
      response = {
          "status": "online",
          "source": {
              "type": "automated",
              "collaborator": null,
              "relay": null
          },
          "collaborators": [],
          "relays": [],
          "current_track": {
              "title": "Dinho Ouro Preto - Quase Sem Querer (Acústica)",
              "start_time": "2024-08-23T16:04:41+00:00",
              "artwork_url": "https://is5-ssl.mzstatic.com/image/thumb/Music113/v4/00/cb/05/00cb050c-b225-da7d-861b-255c59ff7948/194491749712.jpg/100x100bb.jpg",
              "artwork_url_large": "https://is5-ssl.mzstatic.com/image/thumb/Music113/v4/00/cb/05/00cb050c-b225-da7d-861b-255c59ff7948/194491749712.jpg/600x600bb.jpg"
          },
          "history": [
              {
                  "title": "Dinho Ouro Preto - Quase Sem Querer (Acústica)"
              },
              ...
          ],
          "logo_url": "https://images.radio.co/station_logos/sf38694d4a.20190218113644.png",
          "streaming_hostname": "streamer.radio.co",
          "outputs": [
              {
                  "name": "listen",
                  "format": "AAC",
                  "bitrate": 192
              },
              {
                  "name": "low",
                  "format": "AAC",
                  "bitrate": 64
              }
          ]
      }
      */
    console.log("[refreshCurrentTrack]", response);
    if (
      response.status &&
      response.current_track?.title &&
      response.current_track?.artwork_url_large &&
      window.MeuPlay.radio
    ) {
      window.MeuPlay.radioStatus = response.status;
      loadMetadata(
        window.MeuPlay.radio,
        response.current_track.title,
        response.current_track.artwork_url_large
      );
    }
  });
}

(window as any).refreshCurrentTrack = refreshCurrentTrack;
