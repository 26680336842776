import { checkConnection } from "./checkConnection";
import { getAudioElement } from "../player/getAudioElement";
import { isPlaying } from "../player/isPlaying";
import { onOffline } from "./onOffline";

export function connectionMonitor() {
  // descomentar as linhas abaixo para trocar de Wi-Fi para 5G em Android
  setInterval(() => {
    const playing = isPlaying();
    if (!playing) {
      return;
    }
    checkConnection(
      () => {
        document.dispatchEvent(
          new CustomEvent("statusChanged", { detail: "online" })
        );
      },
      () => {
        document.dispatchEvent(
          new CustomEvent("statusChanged", { detail: "offline" })
        );
      }
    );
  }, 5000);

  function onAudioInterrupt() {
    checkConnection(
      () => {
        // online
        console.log("[connectionMonitor] está online");
        if (!window.MeuPlay.player?.isPlaying()) {
          window.MeuPlay.player?.play();
        }
        window.MeuPlay.isOnline = true;
        window.MeuPlay.isOfflinePlaying = false;
      },
      () => {
        // offline
        console.log("[connectionMonitor] está offline");
        window.MeuPlay.isOnline = false;
        window.MeuPlay.isOfflinePlaying = false;
        onOffline();
      }
    );
  }

  function onPlaying() {
    console.log("[connectionMonitor] onPlaying");

    const audio = getAudioElement();

    audio.removeEventListener("stalled", onAudioInterrupt);
    audio.addEventListener("stalled", onAudioInterrupt);

    audio.removeEventListener("ended", onAudioInterrupt);
    audio.addEventListener("ended", onAudioInterrupt);
  }

  function onPlayerReady() {
    console.log("[connectionMonitor] onPlayerReady");

    checkConnection(
      () => {
        console.log(
          "[connectionMonitor] onPlayerReady -> checkConnection result: online"
        );
      },
      () => {
        console.log(
          "[connectionMonitor] onPlayerReady -> checkConnection result: offline"
        );
        document.dispatchEvent(
          new CustomEvent("statusChanged", { detail: "offline" })
        );
      }
    );

    window.MeuPlay.player?.event("audioLoaded", function () {
      console.log("[connectionMonitor] onPlayerReady -> audioLoaded");

      const audio = getAudioElement();

      audio.removeEventListener("playing", onPlaying);
      audio.addEventListener("playing", onPlaying);
    });
  }
  document.removeEventListener("playerReady", onPlayerReady);
  document.addEventListener("playerReady", onPlayerReady, false);
}
