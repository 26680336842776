// atualiza a cor da bolinha
export function updateOfflineModeIndicator(
  color: OfflineModeColor,
  text: string
) {
  setTimeout(() => {
    const defaultIndicator: OfflineModeIndicator = {
      loaded: false,
      color: "red",
      text: "Instalando<br>Modo Offline",
    };
    window.MeuPlay.OfflineModeIndicator =
      window.MeuPlay.OfflineModeIndicator || defaultIndicator;

    if (window.MeuPlay.OfflineModeIndicator.color === "green") {
      return;
    }
    window.MeuPlay.OfflineModeIndicator.color = color;
    window.MeuPlay.OfflineModeIndicator.text = text;
    document.dispatchEvent(new CustomEvent("updateOfflineModeIndicator"));
  }, 1500);
}
