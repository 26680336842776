import { beforePlay } from "../player/beforePlay";
import { getAudioElement } from "../player/getAudioElement";
import { disableOfflineModeListeners } from "./disableOfflineModeListeners";
import { enableOfflineModeListeners } from "./enableOfflineModeListeners";

export function playOffline() {
  enableOfflineModeListeners();
  document.dispatchEvent(
    new CustomEvent("statusChanged", { detail: "offline" })
  );
  const audio = getAudioElement();
  beforePlay(audio);
  if (window.MeuPlay.forceOfflineMode) {
    // mantém os listeners desligados
    setTimeout(() => {
      disableOfflineModeListeners();
    });
  }
}
