import debounce from "just-debounce";
import { useRefreshState } from "../states/useRefreshState";
import { loadMetadata } from "./loadMetadata";
import { refreshCurrentTrack } from "./refreshCurrentTrack";

const $: JQueryStatic = window.jQuery;

const onSongChanged = debounce((infos: RadiocoSongChangedEvent) => {
  console.log("onSongChanged (1)");
  if (window.MeuPlay.forceOfflineMode) {
    return;
  }
  console.log("onSongChanged (2)");
  const status = infos?.message?.status || "online";
  if (status === "offline") {
    // TODO: testar se quando coloca a rádio no estado offline pelo dashboard da Radio.co, o player detecta de acordo
    console.log("[songChanged] status=offline");
    window.MeuPlay.radioStatus = "offline";
    document.dispatchEvent(
      new CustomEvent("statusChanged", { detail: "offline" })
    );
  } else {
    console.log("[songChanged] status=online");
    window.MeuPlay.radioStatus = "online";
    document.dispatchEvent(
      new CustomEvent("statusChanged", { detail: "online" })
    );

    if (window.MeuPlay.radio) {
      console.log("onSongChanged (3)");
      loadMetadata(window.MeuPlay.radio, infos.trackTitle, infos.trackArtwork);
    }
  }
}, 100);

export async function loadRadioCo(radio: TRadio) {
  // Remove HLS player, caso necessário
  const hlsContainer = document.getElementById("hls-container");
  if (hlsContainer) {
    hlsContainer.remove();
  }

  if (radio.url.match(/(s[0-9a-f]{9})/g)) {
    if (window.MeuPlay.player) {
      console.warn("carregando player novamente...");
      await useRefreshState.getState().refresh();
      return;
    } else {
      console.warn("carregando player pela primeira vez...");
    }

    const player = $(".radioplayer").radiocoPlayer();
    window.MeuPlay.player = player;
    document.dispatchEvent(new CustomEvent("playerReady"));

    player.event("songChanged", (event) => {
      console.log("onSongChanged (0)");
      refreshCurrentTrack();
      return onSongChanged(event);
    });
  } else {
    console.error(
      "O URL da rádio não possui um id no formato s[0-9a-f]{9} (exemplo: sf38694d4a)"
    );
  }
}
