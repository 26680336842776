import { create } from "zustand";

export type TRefreshState = {
  blankScreen: boolean;
  refresh: () => Promise<void>;
};

export const useRefreshState = create<TRefreshState>()((set) => ({
  blankScreen: false,
  refresh: () => {
    console.warn("refresh!");
    return new Promise((resolve) => {
      set({ blankScreen: true });
      setTimeout(() => {
        document.querySelectorAll("audio").forEach((audio) => audio.remove());
        delete window.MeuPlay.player;

        set({ blankScreen: false });
      }, 100);
      setTimeout(() => resolve(), 200);
    });
  },
}));
