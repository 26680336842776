import { isHls } from "./isHls";

const $: JQueryStatic = window.jQuery;

export function setCurrentTrack(
  title: string | null,
  artwork: string | undefined,
  offline: boolean
) {
  let song = "";
  let artist = "";
  if (title != null && title !== "") {
    let song_parts = title.split(" - ");
    artist = song_parts[0] ?? "";
    song = song_parts[1] ?? "";
  }
  $("#song").html(song);
  $("#artist").html(artist);

  if (offline) {
    window.MeuPlay.radioStatus = "offline";
  }
  if (window.MeuPlay.radioStatus === "offline") {
    return;
  }
  let $img;
  if (!artwork) {
    artwork = "/images/logo-white-border.png";
  }
  if (window.MeuPlay.radio && isHls(window.MeuPlay.radio)) {
    // HLS (Radio Indoor / Kako)
    $img = $("#artwork");
  } else {
    // Non-HLS (Radio.co)
    $img = $(".radioco-image");
  }
  $img.attr(
    "src",
    artwork.replace("100x100", "600x600").replace(".100.", ".600.")
  );
  $("#bg_blur").css("background-image", "url('" + $img.attr("src") + "')");
}
