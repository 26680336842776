import { onStatusChanged } from "./onStatusChanged";

export function disableOfflineModeListeners() {
  console.log("disableOfflineModeListeners()");
  document.removeEventListener("statusChanged", onStatusChanged);

  // comentar as linhas abaixo para trocar de Wi-Fi para 5G em Android
  // window.removeEventListener("offline", onOffline);
  // window.removeEventListener("online", onOnline);
}
