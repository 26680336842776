import debounce from "just-debounce";
import { getAudioElement } from "./getAudioElement";
import { isSafari } from "../utils/isSafari";
import { getVolume } from "./getVolume";

const $: JQueryStatic = window.jQuery;

export function fadeOutMainAudio() {
  return new Promise<void>((resolve) => {
    const audio = getAudioElement();
    if (isSafari) {
      audio.pause();
      resolve();
    } else {
      if (audio.volume === 0.015) {
        console.log("volume já reduzido.", audio.volume.toFixed(3));
        resolve();
      } else {
        console.log("reduzindo volume...", audio.volume.toFixed(3));
        $(audio).animate({ volume: 0.015 }, 1000, () => {
          console.log("volume reduzido.", audio.volume.toFixed(3));
          resolve();
        });
      }
    }
  });
}

function _playMainAudio(fadeInTime = 1000) {
  setTimeout(() => {
    console.log("[playAnotherAudio] playMainAudio");
    const audio = getAudioElement();
    if (isSafari) {
      pauseOtherAudios(audio);
      audio.play();
    } else {
      const volume = getVolume() / 100;
      $(audio).animate({ volume }, fadeInTime);
    }
  });
}

export const playMainAudio = debounce(_playMainAudio, 500);

export function pauseOtherAudios(ref: HTMLAudioElement) {
  const audio = getAudioElement();
  Array.from(document.querySelectorAll("audio"))
    .filter((el) => el !== ref && el !== audio)
    .forEach((el) => el.pause());
}
