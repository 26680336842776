export class ConsoleService {
  public log(silentMode: boolean, ...args: any[]): void {
    if (!silentMode) {
      console.log("[ConsoleService]", ...args);
    }
  }
}

// import { consoleService as c } from "./services";
// c.log(silentMode, "Hello World");
