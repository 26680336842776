import { ttsLoad } from "./tts/ttsLoad";
import { isTouchDevice } from "../utils/isTouchDevice";
import { askSongLoad } from "./askSongLoad";
import { play } from "../player/play";
import { stop } from "../player/stop";
import { contactFormLoad } from "./contactFormLoad";
import { isPlaying } from "../player/isPlaying";

const $: JQueryStatic = window.jQuery;

export function onFirstLoad(radio: TRadio) {
  console.warn("onFirstLoad()");

  // const tooltipLoaded = typeof $("").tooltip === "function";
  // if (!tooltipLoaded) {
  //   console.error("Bootstrap tooltip not available!");
  // }

  if (!isTouchDevice()) {
    $('[data-toggle="tooltip"]').tooltip({
      delay: { show: 1, hide: 1 },
      trigger: "hover",
    });
  }

  $("#audios_modal").on("hidden.bs.modal", function () {
    $("#audios_modal").removeClass("visible-while-playing");
  });

  $(".botao:has(#play_pause)").click(function (e) {
    e.preventDefault();
    $(".tooltip").tooltip("hide");
    const playing = isPlaying();
    $("#play_pause svg").remove();
    if (playing) {
      console.log("click play_pause -> stop");
      stop();
    } else {
      console.log("click play_pause -> play");
      play();
    }
    return false;
  });

  askSongLoad();

  ttsLoad(radio);

  contactFormLoad(radio);
}
