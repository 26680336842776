import { disableOfflineModeListeners } from "./disableOfflineModeListeners";
import { onStatusChanged } from "./onStatusChanged";

export function enableOfflineModeListeners() {
  console.log("enableOfflineModeListeners()");
  disableOfflineModeListeners();
  document.addEventListener("statusChanged", onStatusChanged, false);

  // comentar as linhas abaixo para trocar de Wi-Fi para 5G em Android
  // window.addEventListener("offline", onOffline);
  // window.addEventListener("online", onOnline);
}
