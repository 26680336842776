import swal from "sweetalert";
import Echo from "laravel-echo";
import { env } from "../env";

type EchoEvent = {
  channel: string;
  message: {
    id: number;
    radio_id: number;
    message: string;
    file?: string;
    /** 2024-08-02 */
    message_sent_date: string;
  };
};

export function loadSocket(radio: TRadio) {
  window.io = require("socket.io-client");
  console.log("Laravel Echo Server host:", env.sockets.host);
  const echo = new Echo({
    broadcaster: "socket.io",
    host: env.sockets.host,
  });

  echo
    .channel(`radiomessage_${radio.folder}`)
    .listen("SendMessage", (event: EchoEvent) => {
      const swalParams = event.message.file
        ? { icon: event.message.file, className: "notif" }
        : { icon: "success", title: "Nova Mensagem!" };
      console.log("event:", event);
      swal({
        ...swalParams,
        content: {
          element: window.jQuery(
            "<div style='display:contents'>" + event.message.message + "</div>"
          )[0],
        },
      });
    });
}
