import { refreshTrackInfo } from "./refreshTrackInfo";
import { setCurrentTrack } from "./setCurrentTrack";

export function loadMetadata(radio: TRadio, title: string, artwork: string) {
  console.log("loadMetadata (1)");
  if (!window.MeuPlay.isOfflinePlaying) {
    console.log("loadMetadata (2)");
    setCurrentTrack(title, artwork, false);
  }
  refreshTrackInfo(radio);
}
