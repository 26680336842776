import { TimerRef } from "../types";
import { getAudioElement } from "./getAudioElement";
import { getVolume } from "./getVolume";
import { refreshCurrentTrack } from "./refreshCurrentTrack";

const $: JQueryStatic = window.jQuery;

const timerRef: TimerRef = {
  timer: undefined,
};

// onPlaying
export function onStart() {
  const initialVolume = getVolume();
  console.log("🔊 Volume inicial:", initialVolume);
  if (window.MeuPlay.player && window.MeuPlay.player.volume) {
    window.MeuPlay.player.volume(initialVolume);
  } else {
    const audio = getAudioElement();
    if (audio) {
      audio.volume = initialVolume / 100;
    }
  }
  $("#overflow_player").removeClass("loading").hide();
  $("#play_pause").html(
    '<div class="fa fa-pause" style="vertical-align: middle;"></div>'
  );
  document.body.classList.remove("stopped");

  if (typeof timerRef.timer !== "undefined") {
    clearTimeout(timerRef.timer);
  }
  timerRef.timer = setInterval(() => {
    if (!window.MeuPlay.isOfflinePlaying) {
      refreshCurrentTrack();
    }
  }, 5000);
}
