function addListener(node: HTMLAudioElement, eventName: string) {
  function onEvent(eventParam: unknown) {
    console.warn("[debugAudio]", eventName, eventParam, node);
  }
  node.removeEventListener(eventName, onEvent);
  node.addEventListener(eventName, onEvent);
}

export function debugAudio(audio: HTMLAudioElement) {
  if (audio.dataset.debugAudio) {
    return;
  }
  if (!audio.dataset.debugAudio) {
    audio.dataset.debugAudio = "1";
  }
  addListener(audio, "error");
  addListener(audio, "loadstart");
  addListener(audio, "abort");
  addListener(audio, "canplay");
  addListener(audio, "playing");
  addListener(audio, "emptied");
  addListener(audio, "ended");
  addListener(audio, "canplaythrough");
  addListener(audio, "loadedmetadata");
  addListener(audio, "loadeddata");
  addListener(audio, "stalled");
  // addListener(audio, "suspend");
  addListener(audio, "waiting");
}
