import { useLoginState } from "../../states/useLoginState";
import { LoginInfoProps } from "./types";

import "./style.scss";
import { useMemo } from "react";

export function LoginInfo({ requireLogin }: LoginInfoProps) {
  const { idRadio, login, logout } = useLoginState((state) => ({
    idRadio: state.idRadio,
    login: state.login,
    logout: state.logout,
  }));

  const cnpj = useMemo(() => {
    if (!login) {
      return "";
    }
    const loginString = String(login || "");
    return loginString.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      "$1.$2.$3/$4-$5"
    );
  }, [login]);

  return (
    <div className="login-info-component">
      <span>{idRadio}</span>
      {requireLogin ? <span>{cnpj}</span> : ""}
      <div>
        <button type="button" onClick={() => logout(true)}>
          Sair
        </button>
      </div>
    </div>
  );
}
