import { useState } from "react";
import "./style.scss";

export function SpeedControl() {
  const [speed, setSpeed] = useState(1);
  const setPlaybackRate = (playbackRate: number) => {
    setSpeed(playbackRate);
    window.MeuPlay.playbackRate = playbackRate;
    console.log("playbackRate=", playbackRate);
  };
  return (
    <div className="speed-control">
      <label>
        Velocidade:{" "}
        <input
          type="range"
          min={0.75}
          max={1.75}
          step={0.05}
          value={speed}
          onChange={(e) => {
            const playbackRate = parseFloat(e.target.value);
            setPlaybackRate(playbackRate);
          }}
        />{" "}
        {speed.toFixed(2)}x
      </label>
      <button type="button" onClick={() => setPlaybackRate(1.0)}>
        Reset
      </button>
    </div>
  );
}
