import { onOffline } from "./onOffline";
import { onOnline } from "./onOnline";

/**
 * ```js
 * document.dispatchEvent(new CustomEvent("statusChanged", { detail: "offline" }));
 * ```
 */
export const onStatusChanged: EventListener = function onStatusChanged(e) {
  const currentStatus = window.MeuPlay.isOnline ? "online" : "offline";
  const newStatus = (e as CustomEvent).detail; // "online" | "offline"
  if (currentStatus === newStatus) {
    // console.log(
    //   "onStatusChanged foi chamado sem necessidade, ignorando chamada..."
    // );
    return;
  }

  if (newStatus === "offline") {
    console.warn("statusChanged to offline");
    onOffline();
  } else {
    console.warn("statusChanged to online");
    onOnline();
  }
};
